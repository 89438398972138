import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { TableCell } from '@mui/material';
import { useEffect } from 'react';
import styled from '@emotion/styled';
import MultiSelectLevelEnum from '../../enum/MultiSelectLevel';
import { useAppContext } from '../../context/AppContext';
import LevelSetSchedule from '../../interfaces/LevelSetSchedule';
import MultiSelectProps from '../../interfaces/MultiSelectProps';
import FormattedMessageRollover from '../../lang/FormattedMessage';

interface CheckBoxProps {
  multiSelectLevelId: MultiSelectLevelEnum|string,
  multiSelectChildId?: string,
  noTableCell?: boolean,
  schoolId?: number,
  schoolName?: string,
  classId?: number,
  className?: string,
  studentId?: number,
  studentName?: string,
  assessments?: LevelSetSchedule[];
  dataTestId: string,

}

const TableCellCustom = styled(TableCell)`
  display: flex;
  align-items: center;
  position: absolute;
  left: 26rem;
`

export default ({
  multiSelectLevelId, multiSelectChildId, noTableCell, schoolId, schoolName, classId, className, studentId, studentName, assessments, dataTestId,
}: CheckBoxProps) => {
  const {
    activeMultiSelectId, setActiveMultiSelectId, multiSelectedRecordsMap, languageId, districtId, summer, disableDatePicker,
  } = useAppContext()
  const [checked, setChecked] = React.useState(false);

  const onMultiLevelSelectParentChange = () => {
    if (!checked) {
      setActiveMultiSelectId(multiSelectLevelId);
      setChecked(true);
      return;
    }
    setActiveMultiSelectId(MultiSelectLevelEnum.Null)
    setChecked(false);
  }

  const onMultiLevelSelectChildChange = () => {
    if (!checked) {
      const obj:MultiSelectProps = {
        schoolId: schoolId ?? 0,
        schoolName: schoolName ?? '',
        classId: classId ?? 0,
        className: className ?? '',
        studentId: studentId ?? 0,
        studentName: studentName ?? '',
        assessments: assessments ?? [],
      }
      if (multiSelectChildId) {
        multiSelectedRecordsMap.set(multiSelectChildId, obj);
      }
      setChecked(true);
      return;
    }
    if (multiSelectChildId && multiSelectedRecordsMap.has(multiSelectChildId)) {
      multiSelectedRecordsMap.delete(multiSelectChildId);
    }
    setChecked(false);
  }

  const onCheckboxChange = () => {
    if (multiSelectChildId) {
      onMultiLevelSelectChildChange();
    } else {
      onMultiLevelSelectParentChange();
    }
  };

  useEffect(() => {
    if (activeMultiSelectId !== MultiSelectLevelEnum.Null && !multiSelectChildId) {
      setActiveMultiSelectId(MultiSelectLevelEnum.Null);
      multiSelectedRecordsMap.clear();
      setChecked(false);
    }
  }, [languageId, districtId, summer]);

  useEffect(() => {
    if (multiSelectLevelId !== activeMultiSelectId) {
      setChecked(false);
    } else if (multiSelectLevelId === activeMultiSelectId
      && !!multiSelectLevelId
      && multiSelectedRecordsMap.has(multiSelectChildId ?? '')) {
      setChecked(true);
    }
  }, [activeMultiSelectId]);

  const renderedComponent = (testIdSuffix: string) => (
    <Checkbox
      disabled={disableDatePicker}
      checked={checked && activeMultiSelectId === multiSelectLevelId}
      onChange={onCheckboxChange}
      inputProps={{ 'aria-label': 'controlled' }}
      data-testid={dataTestId.concat(testIdSuffix)}
    />
  )

  return noTableCell ? renderedComponent('') : (
    <>
      <TableCellCustom data-testid={dataTestId}>
        <FormattedMessageRollover id="enableMultiEdit" defaultMessage="Enable multi edit" />
        <span>
          {renderedComponent('Checkbox')}
        </span>
      </TableCellCustom>
    </>
  )
}
