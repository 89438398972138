import * as React from 'react';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import BootstrapDialog from './BootStrapDialog';
import FormattedMessageRollover from '../../lang/FormattedMessage';
import LexileScore from '../../interfaces/LexileScore';
import { useAppContext } from '../../context/AppContext';
import BackdropLoader from '../shared/BackdropLoader';
import ErrorDetail from '../../util/ErrorDetail';

interface LevelSetPopupProps {
  assessmentIdLabel: string;
  studentId: number;
  studentName: string;
  classId: number;
  displayScore: string;
  scores: LexileScore[];
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const ErrorSpanDiv = styled.div`
  color: red;
  display: block;
  padding-top: 0.25rem;
  padding-left: 0.875rem;
`
const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, marginRight: '2rem' }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function LevelSetPopup({
  assessmentIdLabel, studentId, studentName, classId, displayScore, scores,
}: LevelSetPopupProps) {
  const {
    districtId, languageId, allowReset, summer, schoolYearEnd, httpClient: { getResetEligibility, resetLevelSetTest },
  } = useAppContext();
  const queryClient = useQueryClient();

  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState('');
  const [resetable, setResetable] = React.useState(false);
  const [isResetting, setResetting] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  const {
    data: resetEligibilityData,
  } = getResetEligibility({
    userId: studentId,
    classId,
    assessmentId: scores[0].assessment_id,
    assessmentDateId: scores[0].assessment_date_id,
    languageId: scores[0].language_id,
    timeTaken: scores[0].time_taken,
    isEnabled: allowReset && open,
  });

  useEffect(() => {
    if (resetEligibilityData) {
      setResetable(resetEligibilityData.isEligible);
    }
  }, [resetEligibilityData])

  const handleReset = async () => {
    let shouldClosePopup = false;
    setResetting(true);
    setResetable(false);
    const res = await resetLevelSetTest({
      userId: studentId,
      assessmentId: scores[0].assessment_id,
      assessmentDateId: scores[0].assessment_date_id,
      languageId: scores[0].language_id,
    });

    if (res instanceof ErrorDetail) {
      setResetting(false);
      setResetable(true);
      setError('resetFailure');
      return;
    }

    if (res) {
      shouldClosePopup = true;
    }

    await queryClient.invalidateQueries(['students', districtId, classId, languageId, summer, schoolYearEnd]);
    setResetting(false);

    if (shouldClosePopup) {
      setResetable(true);
      handleClose();
    }
  }

  const renderData = (counter: number, lexile: LexileScore, disableReset: boolean) => {
    const formattedScore = (score : number) => {
      const roundScore = Math.round(score / 5) * 5;
      if (roundScore < 0) {
        return `BR${Math.abs(roundScore)}L`;
      }
      return `${roundScore}L`;
    }
    const displayProvider = (a3kLevelset : string) => {
      if (a3kLevelset === '1') {
        return (
          <FormattedMessageRollover id="providerA3K" defaultMessage="" />
        );
      }
      return (
        <FormattedMessageRollover id="providerNWEA" defaultMessage="" />
      );
    }
    const prefixZero = (v: string): string => (v.length === 1 ? `0${v}` : v)
    const displayDate = (timeTaken : string) => {
      const d = new Date(timeTaken.replace(/-/g, '/'));
      const { user: { isInternational } } = useAppContext();
      if (isInternational) {
        return `${d.getFullYear()}`.concat('/', prefixZero(`${d.getDate()}`), '/', prefixZero(`${d.getMonth() + 1}`));
      }
      return `${d.getFullYear()}`.concat('-', prefixZero(`${d.getMonth() + 1}`), '-', prefixZero(`${d.getDate()}`));
    }
    return (
      <span key={`lexiles_${counter}`}>
        <hr />
        <h4>
          <FormattedMessageRollover id="lexileProvider" defaultMessage="Lexile Provider" />
          : &nbsp;
          {displayProvider(lexile.a3k_levelset)}
        </h4>
        <h4>
          <FormattedMessageRollover id="dateCompleted" defaultMessage="Date Completed" />
          : &nbsp;
          {displayDate(lexile.time_taken)}
        </h4>
        <h4>
          <FormattedMessageRollover id="lexileScore" defaultMessage="Lexile Score" />
          : &nbsp;
          {formattedScore(lexile.score)}
        </h4>
        {allowReset && counter === 0
              && (
                <Button autoFocus variant="outlined" onClick={handleReset} disabled={disableReset} data-testid="resetButton">
                  <FormattedMessageRollover id="reset" defaultMessage="Reset" />
                </Button>
              )}
      </span>
    )
  }

  return (
    <div style={{
      marginTop: '0.2rem', marginBottom: '0.2rem',
    }}
    >
      {isResetting && <BackdropLoader messageId="resetting" /> }
      <Button variant="outlined" onClick={handleClickOpen} style={{ textTransform: 'none' }}>
        {displayScore}
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="levelset-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="levelset-dialog-title" onClose={handleClose}>
          <FormattedMessageRollover id="levelSetInfo" defaultMessage="LevelSet Information" />
          : &nbsp;
          <FormattedMessageRollover id={assessmentIdLabel} defaultMessage="" />
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <h4>
            <FormattedMessageRollover id="student" defaultMessage="Student" />
            : &nbsp;
            { ` ${studentName}` }
          </h4>
          { scores.map((x, index) => renderData(index, x, !resetable))}
        </DialogContent>
        {error && (
          <ErrorSpanDiv>
            <FormattedMessageRollover
              id={error}
              defaultMessage="Error resetting test"
            />
          </ErrorSpanDiv>
        ) }
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            <FormattedMessageRollover id="close" defaultMessage="Close" />
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
