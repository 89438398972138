import * as React from 'react';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FormattedMessageRollover from '../lang/FormattedMessage';
import { useAppContext } from '../context/AppContext';
import SummerEnum from '../enum/Summer';
import { testScheduleHeaders } from './shared/SchedulerTableView';

interface TestNameProps {
  onScroll: (e: React.UIEvent<HTMLDivElement>) => void
}

export default React.forwardRef<HTMLInputElement, TestNameProps>(({ onScroll }: TestNameProps, ref) => {
  const { summer, isInterimIITestDisabled } = useAppContext();
  const disabledTests = [isInterimIITestDisabled ? 3 : 0];
  return (
    <TableContainer
      component={Paper}
      onScroll={onScroll}
      ref={ref}
      className="cs-scroll"
    >
      <Table aria-label="Test Names Header">
        <TableHead tabIndex={0}>
          <TableRow data-testid="tableRowTestNames">
            <TableCell
              colSpan={7}
              sx={{
                width: 340, minWidth: 340,
              }}
              align="center"
            />
            {testScheduleHeaders.map((header) => {
              if (disabledTests.includes(Number(header.id))) {
                return null;
              }
              if (header.summer === SummerEnum.No || header.summer === summer) {
                return (
                  <TableCell
                    tabIndex={0}
                    key={`${header.labelId}_${header.id}`}
                    align="center"
                    sx={{ minWidth: '235px' }}
                  >
                    <FormattedMessageRollover id={header.labelId} defaultMessage={header.defaultLabel} />
                  </TableCell>
                )
              }
              return null;
            })}
          </TableRow>
        </TableHead>
      </Table>
    </TableContainer>
  );
});
