import React from 'react';
import { useIntl } from 'react-intl';
import { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat';
import useRolloverIntl from './useRolloverIntl';

export interface FormattedMessageProps {
  id: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>;
  defaultMessage?: string;
  dataTestId?: string;
  styles?: React.CSSProperties;
}

const FormattedMessageRollover = ({
  id, values, defaultMessage, dataTestId, styles,
}: FormattedMessageProps) => {
  const intl = useIntl();

  const { text, events } = useRolloverIntl(intl, id, values, defaultMessage || '');

  return dataTestId ? (
    <span {...events} data-testid={dataTestId} style={styles}>
      {text}
    </span>
  ) : (
    <span {...events} style={styles}>
      {text}
    </span>
  );
};

export default FormattedMessageRollover;
