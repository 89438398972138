import React, { ChangeEvent } from 'react';
import { DistrictColumnSource } from '../interfaces/DistrictColumnSource';
import SchoolTableComponent from './SchoolTableComponent';
import CenteredRowMessage from './shared/CenteredRowMessage';
import School from '../interfaces/School';
import { useAppContext } from '../context/AppContext';
import LevelSetSchedule from '../interfaces/LevelSetSchedule';

interface SchoolTableProps {
  districtSchedule: LevelSetSchedule[];
  loadSASchool?: boolean;
  schoolId?: number;
}

const SchoolComponent = ({
  districtSchedule, loadSASchool, schoolId,
}: SchoolTableProps) => {
  const {
    districtId, programId, languageId, user, summer, schoolYearEnd, httpClient: { getSchoolData, getSchoolAdminData },
  } = useAppContext();
  const [page, setPage] = React.useState(0);
  const [perPageRows, setPerPageRows] = React.useState(10);
  const { data: schools = [], isLoading } = getSchoolData({
    districtId, programId, languageId, isEnabled: !loadSASchool, summer, schoolYearEnd,
  })
  const { data: saSchools = [], isLoading: saIsLoading } = getSchoolAdminData(
    {
      districtId, programId, languageId, isEnabled: !!loadSASchool, summer, schoolYearEnd,
    }, user?.id || 0,
  )
  const [searchKey, setSearchKey] = React.useState('');

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleSearchKeyChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPage(0);
    setSearchKey(event.target.value);
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPerPageRows(+event.target.value);
    setPage(0);
  };

  const getSchoolRows = (): School[] => {
    let schoolData = schools;
    if (loadSASchool) {
      schoolData = saSchools;
    }
    if (searchKey !== '' && searchKey.length > 0) {
      const res = schoolData?.filter((x) => x.school_name.toLowerCase().includes(searchKey.toLowerCase()));
      return res === undefined ? [] : res;
    }
    return schoolData;
  }

  // Todo cleanup header related code once verified that they are not required
  const schoolColumns: DistrictColumnSource[] = [
    {
      id: 'showClasses', label: 'expand', showLabel: true,
    },
    {
      id: 'name', label: 'School Name', showLabel: true,
    },
  ];
  return isLoading || saIsLoading ? (
    <CenteredRowMessage
      messageId="loading"
      defaultMessage="Loading..."
      componentKey={`schoolComponentLoadingMsg_${districtId}`}
    />
  ) : (
    <>
      {schools.length === 0 && saSchools.length === 0 ? (
        <CenteredRowMessage
          messageId="noRows"
          defaultMessage="No Rows"
          componentKey={`schoolComponentNoRowsMsg_${districtId}`}
        />
      ) : (
        <SchoolTableComponent
          headers={schoolColumns}
          rows={getSchoolRows()}
          districtSchedule={districtSchedule}
          page={page}
          perPageRows={perPageRows}
          defaultSchoolId={schoolId}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handlePageChange={handleChangePage}
          handleSearch={handleSearchKeyChange}
        />
      )}
    </>
  );
}

export default SchoolComponent;
