import React, { ChangeEvent, useEffect } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { TablePagination } from '@mui/material';
import School from '../interfaces/School';
import { MinTableItem, TableHeaders } from './shared/TableHeaders';
import ClassComponent from './ClassComponent';
import SearchBar from './shared/SearchBar';
import FormattedMessageRollover from '../lang/FormattedMessage';
import SchedulerTableView from './shared/SchedulerTableView';
import { SVGNameButton, TableRowPagination } from '../styled/styles';
import PaginationSpacer from './shared/PaginationSpacer';
import PaginationLabel from './shared/PaginationLabel';
import { useAppContext } from '../context/AppContext';
import { getDate, parseAssessmentSchedule } from '../util/shared';
import LevelSetSchedule from '../interfaces/LevelSetSchedule';
import Checkbox from './shared/Checkbox';
import MultiSelectLevelEnum from '../enum/MultiSelectLevel';

interface TableProps<T extends MinTableItem> {
  headers: TableHeaders<T>[];
  rows: School[];
  page: number;
  districtSchedule: LevelSetSchedule[];
  defaultSchoolId?: number;
  handlePageChange: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  handleSearch: (event: ChangeEvent<HTMLInputElement| HTMLTextAreaElement>) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  perPageRows: number;
}

interface RowProps {
  rows: School[];
  districtSchedule: LevelSetSchedule[];
  defaultSchoolId?: number;
}

export const MapRows = ({ rows, districtSchedule, defaultSchoolId }: RowProps) => {
  const { languageId, activeMultiSelectId } = useAppContext();
  // Todo move this function to util or shared function and generate history data popup;
  const getMapping = () => {
    const map = new Map<string, boolean>()
    if (defaultSchoolId && defaultSchoolId > 0) {
      map.set(`schools_${defaultSchoolId}`, true);
    }
    return map;
  }
  const [visited, setVisited] = React.useState(getMapping());
  const handleOpenClick = (key: string, id: number) => {
    if (!visited.has(`${key}_${id}`)) {
      setVisited(new Map(visited.set(`${key}_${id}`, true)));
    } else {
      visited.delete(`${key}_${id}`)
      setVisited(new Map(visited));
    }
  }
  useEffect(() => {
    setVisited(getMapping())
  }, [languageId])
  return (
    <>
      {rows.map((row) => {
        const schoolAssessmentSchedule = parseAssessmentSchedule(row.assesments, districtSchedule);
        const dateRange = {
          schoolStart: getDate(row.school_start),
          schoolEnd: getDate(row.school_end),
          summerSchoolStart: row.summer_school_start ? getDate(row.summer_school_start) : null,
          summerSchoolEnd: row.summer_school_end ? getDate(row.summer_school_end) : null,
        }
        return (
          <React.Fragment key={`schools_${row.school_id}`}>
            <TableRow key={`schools_${row.school_name}`} sx={{ padding: 0 }} data-testid="schoolTableRow">
              <TableCell sx={{
                width: 340, minWidth: 340, paddingLeft: 0, paddingRight: '2rem',
              }}
              >
                {activeMultiSelectId === MultiSelectLevelEnum.School && (
                  <Checkbox
                    multiSelectLevelId={MultiSelectLevelEnum.School}
                    multiSelectChildId={`${MultiSelectLevelEnum.SchoolPrefix}${row.school_id}`}
                    noTableCell
                    schoolId={row.school_id}
                    schoolName={row.school_name}
                    assessments={row.assesments}
                    dataTestId="checkboxSchool"
                  />
                )}

                <SVGNameButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => { handleOpenClick('schools', row.school_id) }}
                >
                  {visited.has(`schools_${String(row.school_id)}`) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  {row.school_name}
                </SVGNameButton>
              </TableCell>
              <SchedulerTableView
                scheduler={schoolAssessmentSchedule}
                hasSummerAccess={row.summer_access}
                keyPrefix={`school_${row.school_id}`}
                schoolId={row.school_id}
                schoolName={row.school_name}
                multiSelectLevel={MultiSelectLevelEnum.School}
                dateRange={dateRange}
              />
            </TableRow>
            {visited.has(`schools_${String(row.school_id)}`)
              ? (
                <ClassComponent
                  schoolId={row.school_id}
                  hasSummerAccess={row.summer_access}
                  schoolSchedule={schoolAssessmentSchedule}
                  dateRange={dateRange}
                />
              )
              : null}
          </React.Fragment>
        )
      })}
    </>
  )
};

function SchoolTableComponent<T extends MinTableItem>({
  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  headers, rows, page, districtSchedule, handlePageChange, handleSearch, perPageRows, handleChangeRowsPerPage, defaultSchoolId,
}: TableProps<T>) {
  return (
    <>
      <TableRow>
        <SearchBar
          id="search-school"
          labelId="searchSchool"
          handleSearch={handleSearch}
        />
        <Checkbox multiSelectLevelId={MultiSelectLevelEnum.School} dataTestId="multiSelectTableDataSchool" />
      </TableRow>
      <MapRows
        rows={rows.slice(page * perPageRows, (page * perPageRows) + perPageRows)}
        districtSchedule={districtSchedule}
        defaultSchoolId={defaultSchoolId}
      />
      <TableRowPagination justifyContent="flex-end">
        <td>
          <TablePagination
            id="pagination-schools"
            rowsPerPageOptions={[5, 10, 20, 40]}
            component="div"
            count={rows.length}
            rowsPerPage={perPageRows}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            labelRowsPerPage={<FormattedMessageRollover id="rowsPerPage" />}
            labelDisplayedRows={({ from, to, count }) => (
              <PaginationLabel from={from} to={to} count={count} />
            )}
          />
        </td>
      </TableRowPagination>
      <PaginationSpacer />
      <PaginationSpacer />
    </>
  )
}

export default SchoolTableComponent;
