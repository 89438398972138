let KIDBIZ_HOST = '';
let SA_HOST = '';
let LEVELSET_HOST = '';

let kbUrl = process.env.REACT_APP_KIDBIZ_HOST;
if (window.location.host.indexOf('.net') !== -1) {
  kbUrl = process.env.REACT_APP_KIDBIZ_HOST_NET;
}
if (process.env.REACT_APP_ENV === 'qa') {
  kbUrl = window.location.origin;
}
KIDBIZ_HOST = kbUrl;
SA_HOST = process.env.REACT_APP_SA_HOST;
LEVELSET_HOST = process.env.REACT_APP_LEVELSET_HOST;

const config = {
  KIDBIZ_HOST,
  SA_HOST,
  LEVELSET_HOST,
};
export default config;
