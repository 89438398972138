import * as React from 'react';
import { createTheme, ThemeProvider as ThemeProviderReact } from '@mui/material/styles';

declare module '@emotion/react' {
  export interface Theme {
    palette: {
      error: {
        main: string
      }
    }
  }
}

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontWeight: 'inherit',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: 'inherit',
        },
      },
    },
  },
  palette: {
    text: {
      disabled: 'rgba(0, 0, 0, 0.6)',
    },
    error: {
      main: '#d32f2f',
    },
    action: {
      disabled: 'rgba(0, 0, 0, 0.6)',
    },
  },
});

const ThemeProvider: React.FC = ({ children }) => (
  <ThemeProviderReact theme={theme}>
    {children}
  </ThemeProviderReact>
)

export default ThemeProvider;
