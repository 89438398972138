import React from 'react';
import { FormattedMessage } from 'react-intl';

export interface LabelDisplayedRowsArgs {
  from: number;
  to: number;
  count: number;
}

export default ({
  from, to, count,
}: LabelDisplayedRowsArgs) => (
  <span>
    <span>{` ${from}`}</span>
    -
    <span>{`${to} `}</span>
    <FormattedMessage id="of" defaultMessage="of" />
    <span>{` ${count} `}</span>
  </span>
);
