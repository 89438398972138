import * as React from 'react';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useQueryClient } from 'react-query'
import { Box } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { CheckCircle, Error } from '@mui/icons-material';
import PostScheduleObject from '../../interfaces/PostScheduleObject';
import FormattedMessageRollover from '../../lang/FormattedMessage';
import BootstrapDialog from './BootStrapDialog';
import BackdropLoader from '../shared/BackdropLoader';
import { useAppContext } from '../../context/AppContext';
import { isValidObject } from '../../util/shared';
import ErrorDetail from '../../util/ErrorDetail';
import { ErrorResponseData } from '../../interfaces/ErrorDetail';

// Todo add localization for error strings
interface SaveConfirmationPopupProps {
  postScheduleObject: PostScheduleObject
  visible: boolean
  prevDate: Date|null
  currDate: Date|null
  onClose: (date: Date|null)=>void
  dateFormat: string
  activeRecordKey: string
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

interface ErrorType {
  schedulerKey: string,
  localizationKey: string,
}

const SpanBold = styled.span`
  font-weight: bold;
  display: inline-block;
`
const SpanHeader = styled.span`
  display: block;
  padding-bottom: 1rem;
  padding-top: 1rem;
  font-weight: bold;
  font-size: large;
`

const ErrorMapDiv = styled.div`
  padding-top: 0.25rem;
`
const ErrorSpanDiv = styled.div`
  color: red;
  display: block;
  padding-top: 0.25rem;
  padding-left: 0.875rem;
`
const ErrorListDiv = styled.div`
 display: inline-flex;
 align-items: center; 
`
const HeaderDiv = styled.div`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid grey;
`

function isString(val: unknown) {
  return typeof val === 'string' || val instanceof String;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2, marginRight: '2rem' }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const formatCustomDate = (d: string, format: string): JSX.Element|string => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  if (d.length === 0) {
    return d;
  }
  if (d === '0000-00-00') {
    return <FormattedMessageRollover id="cancelTest" defaultMessage="Cancel Test" />;
  }
  const date = new Date(d);
  const month = <FormattedMessageRollover id={months[date.getMonth()]} defaultMessage={`${date.getMonth() + 1}`} />
  if (format === 'MMM dd, yyyy') {
    return (
      <p>
        {month}
        &nbsp;
        {date.getDate().toString().length === 1 ? '0'.concat(date.getDate().toString()) : date.getDate()}
        ,&nbsp;
        {date.getFullYear()}
      </p>
    )
  }
  return (
    <p>
      {date.getDate().toString().length === 1 ? '0'.concat(date.getDate().toString()) : date.getDate()}
      &nbsp;
      {month}
      &nbsp;
      {date.getFullYear()}
    </p>
  )
}

export default function SaveConfirmationPopup({
  postScheduleObject, visible, onClose, prevDate, currDate, dateFormat, activeRecordKey,
}: SaveConfirmationPopupProps) {
  const [open, setOpen] = React.useState(visible);
  const [isLoadingSchedule, setLoadingSchedule] = React.useState(false);
  const [errors, setErrors] = React.useState<{error: ErrorType[], status: number}|undefined>(undefined);
  const [date, setDate] = React.useState<Date|null>(prevDate);
  const [override, setOverride] = React.useState(false);
  const queryClient = useQueryClient();
  const { httpClient: { postLevelSetSchedule } } = useAppContext();
  const {
    assessmentLabelId, scheduleTime, districtName, assessmentSchedule, districtId,
  } = postScheduleObject;
  const handleClose = () => {
    setOpen(false);
    setErrors(undefined);
    onClose(date);
  };

  const handleCloseAfterSave = (newDate: Date|null) => {
    onClose(newDate);
    setOpen(false);
  }

  const findErrorWithKey = (schedulerKey: string): string => {
    if (errors !== undefined) {
      for (let i = 0; i < errors.error.length; i += 1) {
        if (errors.error[i].schedulerKey === schedulerKey) {
          return errors.error[i].localizationKey;
        }
      }
    }
    return '';
  }

  const setErrorDataAndCheckActiveRecordSuccess = (msg: null|string|string[], status:number) => {
    let activeRecordSuccess = true;
    if (msg) {
      const errorArray : ErrorType[] = [];
      const errorData = Array.isArray(msg) ? msg : [msg];
      errorData.forEach((errString) => {
        const strParts = errString.split(':');
        activeRecordSuccess = !(strParts[0].trim() === activeRecordKey);
        if (strParts.length === 2) {
          errorArray.push({ schedulerKey: strParts[0].trim(), localizationKey: strParts[1].trim() })
          return;
        }
        errorArray.push({ schedulerKey: 'unknown', localizationKey: 'unknownError' });
      })
      setErrors({ error: errorArray, status });
    }
    return activeRecordSuccess;
  }

  const handleSave = async () => {
    let finalDate = currDate;
    let shouldClosePopup = true;
    setLoadingSchedule(true);
    const res = await postLevelSetSchedule({ ...postScheduleObject, ...{ isOverride: override ? 1 : 0 } });
    if (res instanceof ErrorDetail) {
      setLoadingSchedule(false);
      setDate(prevDate);
      setErrorDataAndCheckActiveRecordSuccess(res.errorResponse.errorMsg, res.status)
      return;
    }

    if (res
      && isValidObject<{message: unknown}>(res, ['message'])
      && isValidObject<ErrorResponseData>(res.message, ['errorMsg', 'errorCode'])
    ) {
      finalDate = setErrorDataAndCheckActiveRecordSuccess(res.message.errorMsg, 200) ? currDate : prevDate;
      shouldClosePopup = false;
    }
    await queryClient.invalidateQueries();
    setLoadingSchedule(false);
    setDate(finalDate)
    if (shouldClosePopup) {
      handleCloseAfterSave(currDate)
    }
  }

  const renderData = (
    headerId: string,
    value: string|JSX.Element, key: string,
    counter = 0,
    showHeader = false,
    checkForErrors = true,
  ) => {
    const errorKey = checkForErrors ? findErrorWithKey(key) : '';
    return (
      <HeaderDiv key={key}>
        {(counter === 1) && (
          <SpanHeader>
            <FormattedMessageRollover id={headerId} />
          </SpanHeader>
        )}
        <SpanBold>
          {showHeader ? (
            <span>
              <FormattedMessageRollover id={headerId} />
              :&nbsp;
            </span>
          ) : null}
          {counter > 0 && (
            <span>
              {counter}
              .&nbsp;
            </span>
          )}
        </SpanBold>
        {isString(value) ? (
          <ErrorListDiv>
            <span>
              {value}
            </span>
            {errorKey && (
              <span>
                <Error color="error" />
              </span>
            )}
            {!errorKey && errors && errors.error.length > 0 && (
              <span>
                <CheckCircle color="success" />
              </span>
            )}
          </ErrorListDiv>
        ) : value}
        {errorKey ? (
          <ErrorSpanDiv>
            <FormattedMessageRollover
              id={ErrorDetail.getErrorKey(errorKey)}
              defaultMessage="Error updating record"
            />
          </ErrorSpanDiv>
        ) : null }

      </HeaderDiv>
    )
  }
  const showOverrideCheckbox = (schedule : {
    id: number | null, schoolId: number, schoolName: string, classId: number, className:string, studentId: number, studentName: string
  }) => {
    if (schedule.studentId > 0) {
      return false;
    }
    return true;
  }

  let counter = 0;
  return (
    <div style={{ marginTop: '0.2rem', marginBottom: '0.2rem', position: 'absolute' }} data-testid="saveConfirmationPopup">
      {isLoadingSchedule && <BackdropLoader messageId="saving" /> }
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="levelset-confirmation-title"
        open={open}
      >
        <BootstrapDialogTitle id="levelset-confirmation-title" onClose={handleClose}>
          <FormattedMessageRollover id="scheduleConfirmation" defaultMessage="Schedule confirmation" />
          : &nbsp;
          <FormattedMessageRollover id={assessmentLabelId} defaultMessage="" />
        </BootstrapDialogTitle>
        <DialogContent dividers>
          { showOverrideCheckbox(assessmentSchedule[0])
            && (
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={override}
                    onChange={() => setOverride(!override)}
                    inputProps={{ 'aria-label': 'override below levels checkbox' }}
                    data-testid="checkboxOverride"
                  />
                )}
                label={<FormattedMessageRollover id="override" defaultMessage="override " />}
              />
            ) }
          {renderData('scheduleTime', formatCustomDate(scheduleTime, dateFormat), 'scheduler_time_confirmation', 0, true, false)}
          {districtName && renderData('districtName', districtName, `scheduler_${districtId}_0_0_0`, 1, false)}
          {assessmentSchedule.map((x) => {
            counter += 1;
            const key = `scheduler_${districtId}_${x.schoolId}_${x.classId}_${x.studentId}`;
            if (x.schoolName) {
              return renderData('Schools', x.schoolName, key, counter, false)
            }
            if (x.className) {
              return renderData('Classes', x.className, key, counter, false)
            }
            if (x.studentName) {
              return renderData('Students', x.studentName, key, counter, false)
            }
            return null;
          })}

          {(errors !== undefined && errors.status === 200 && errors.error.length === 0) && (
            <Box>
              <FormattedMessageRollover id="saveSuccess" />
            </Box>
          )}

          {(errors !== undefined && errors.status !== 200) && (
            <Box>
              <ErrorMapDiv>
                <FormattedMessageRollover id="saveFailure" />
              </ErrorMapDiv>
            </Box>
          )}

          {(errors !== undefined && errors.status === 200 && errors.error.length > 0) && (
            <Box>
              <ErrorMapDiv>
                <FormattedMessageRollover id="savePartialSuccess" />
              </ErrorMapDiv>
            </Box>
          )}
        </DialogContent>
        {errors === undefined ? (
          <DialogActions>
            <Button autoFocus onClick={handleClose} data-testid="saveConfirmationDiscardButton">
              <FormattedMessageRollover id="discard" defaultMessage="Discard" />
            </Button>

            <Button autoFocus onClick={handleSave} data-testid="saveConfirmationConfirmButton">
              <FormattedMessageRollover id="confirm" defaultMessage="Confirm" />
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button
              autoFocus
              onClick={handleClose}
              data-testid="saveConfirmationDiscardButton"
            >
              <FormattedMessageRollover id="ok" defaultMessage="Ok" />
            </Button>
          </DialogActions>
        )}
      </BootstrapDialog>
    </div>
  );
}
