import * as React from 'react';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  MenuItem, Select,
} from '@mui/material';
import BootstrapDialog from './BootStrapDialog';
import FormattedMessageRollover from '../../lang/FormattedMessage';
import { useAppContext } from '../../context/AppContext';
import BackdropLoader from '../shared/BackdropLoader';
import ErrorDetail from '../../util/ErrorDetail';

interface LocatorTestLevelPopupProps {
  studentId: number;
  studentName: string;
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const ErrorSpanDiv = styled.div`
  color: red;
  display: block;
  padding-top: 0.25rem;
  padding-left: 0.875rem;
`
const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, marginRight: '2rem' }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function LocatorTestLevelPopup({
  studentId, studentName,
}: LocatorTestLevelPopupProps) {
  const {
    programId, languageId, schoolYearEnd,
    httpClient: { getLocatorTestLevels, getUserLocatorTestLevel, saveUserLocatorTestLevel },
  } = useAppContext();

  const [open, setOpen] = React.useState(false);
  const [isSavingLevel, setSavingLevel] = React.useState(false);
  const [error, setError] = React.useState('');
  const [selected, setSelectedValue] = React.useState('');
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setError('');
    setOpen(false);
  };
  const handleSave = async () => {
    setSavingLevel(true);
    const res = await saveUserLocatorTestLevel({
      userId: studentId,
      productId: Number(programId),
      languageId: Number(languageId),
      levelExternalId: selected,
      isEnabled: true,
    });
    setSavingLevel(false);
    if (res instanceof ErrorDetail) {
      setError('saveLevelFailure')
      return;
    }
    handleClose();
  }
  const { data: userLevel } = getUserLocatorTestLevel({
    userId: studentId,
    productId: Number(programId),
    languageId: Number(languageId),
    levelExternalId: '',
    isEnabled: open,
  });
  const getAcademicYearId = () => {
    const academicYears: {[key: number]: number} = {
      2022: 39,
      2023: 41,
      2024: 43,
      2025: 45,
    }
    return academicYears[schoolYearEnd];
  }
  const { data: locatorTestLevels } = getLocatorTestLevels({
    productId: Number(programId),
    languageId: Number(languageId),
    academicYearId: getAcademicYearId(),
    isEnabled: open,
  });

  React.useEffect(() => {
    if (open) {
      setSelectedValue(userLevel?.levelExternalId ?? '');
    }
  }, [userLevel, open])
  return (
    <div style={{
      marginTop: '0.2rem', marginBottom: '0.2rem',
    }}
    >
      <Button variant="outlined" onClick={handleClickOpen} style={{ textTransform: 'none' }}>
        { ` ${studentName}` }
      </Button>
      {isSavingLevel && <BackdropLoader messageId="saving" /> }
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="locatortest-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="locatortest-dialog-title" onClose={handleClose}>
          <FormattedMessageRollover id="levelSetInfo" defaultMessage="LevelSet Information" />
          : &nbsp;
          <FormattedMessageRollover id="locatorTestLevel" defaultMessage="Locator Test Level" />
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <h4>
            <FormattedMessageRollover id="student" defaultMessage="Student" />
            : &nbsp;
            { ` ${studentName}` }
          </h4>
          <h4>
            <FormattedMessageRollover id="Level" defaultMessage="Level" />
            : &nbsp;
            <Select
              sx={{ width: 300 }}
              labelId="level-select"
              value={selected}
              label="selectLevel"
              onChange={(event) => {
                setSelectedValue(event.target.value as string);
              }}
            >
              { locatorTestLevels
               && locatorTestLevels.map((locatorTestLevel) => (
                 <MenuItem
                   value={locatorTestLevel.levelExternalId}
                   key={locatorTestLevel.levelExternalId}
                 >
                   {locatorTestLevel.levelName}
                 </MenuItem>
               ))}
            </Select>
          </h4>

        </DialogContent>
        {error && (
          <ErrorSpanDiv>
            <FormattedMessageRollover
              id={error}
              defaultMessage="Error saving"
            />
          </ErrorSpanDiv>
        ) }
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            <FormattedMessageRollover id="cancel" defaultMessage="Cancel" />
          </Button>
          <Button autoFocus onClick={handleSave}>
            <FormattedMessageRollover id="save" defaultMessage="Save" />
          </Button>
        </DialogActions>
      </BootstrapDialog>

    </div>
  );
}
