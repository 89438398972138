import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {
  Link, Outlet, useLocation, useOutletContext,
} from 'react-router-dom';
import { useEffect } from 'react';
import styled from '@emotion/styled';
import { useAppContext } from '../../context/AppContext';
import PermissionsResponse from '../../interfaces/api/Permissions/PermissionsResponse';
import FormattedMessageRollover from '../../lang/FormattedMessage';
import SummerEnum from '../../enum/Summer';
import { getCurrentSchoolYear } from '../../util/shared';

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #506077;
  @media (max-width: 1322px) {
    
  }
`
const NavButton = styled(Button)<{ border: string }>`
 border: ${(props) => (props.border)};
`
type PermissionsContext = { permissionsData: PermissionsResponse | undefined, isPermissionsLoading: boolean, isPermissionsLoadingError: boolean };
const navItems = [
  { name: 'home', route: '/levelset/scheduler', pathMatch: 'scheduler' },
  { name: 'permissions', route: '/levelset/scheduler/permissions', pathMatch: 'permissions' },
];

export default function DrawerAppBar() {
  const {
    districtId, user: { schoolId, programId, summerAccess }, httpClient: { getPermissions }, setInterimIITestDisabled, setIsSummer, schoolYearEnd,
  } = useAppContext();

  const {
    data: permissionsData, isLoading: isPermissionsLoading, isError: isPermissionsLoadingError,
  } = getPermissions({
    districtId, schoolId: schoolId ?? 0, programId, isEnabled: !!districtId,
  });

  useEffect(() => {
    if (permissionsData !== undefined) {
      setInterimIITestDisabled(true);
      setIsSummer(
        (getCurrentSchoolYear() >= schoolYearEnd
      && summerAccess
      && permissionsData?.levelset_scheduler_include_summer?.value) ? SummerEnum.Yes : SummerEnum.No,
      )
    }
  }, [permissionsData])
  const permissionsContextVal: PermissionsContext = {
    permissionsData,
    isPermissionsLoading,
    isPermissionsLoadingError,
  }

  const { pathname } = useLocation();
  const getTopNavMargins = () => {
    if (window.location.hostname === 'localhost') {
      return '0';
    }
    return Number(programId) === 1 ? '4.25rem' : '7.25rem';
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        component="nav"
        sx={{
          top: getTopNavMargins(), position: 'absolute', backgroundColor: '#fff', zIndex: 0,
        }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'block', sm: 'block' } }}
          >
            <StyledLink to="/levelset/scheduler">
              <FormattedMessageRollover id="levelSetScheduler" />
            </StyledLink>
          </Typography>
          <Box sx={{ display: { xs: 'block', sm: 'block' } }}>
            {navItems.map((item) => (
              <NavButton key={item.name} border={[...pathname.split('/')].pop() === item.pathMatch ? '2px solid grey' : 'none'}>
                <StyledLink to={item.route}>
                  <FormattedMessageRollover id={item.name} defaultMessage={item.name} />
                  {' '}
                </StyledLink>
              </NavButton>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <main style={{ marginTop: '5rem', width: '100%' }}>
        <Outlet context={permissionsContextVal} />
      </main>
    </Box>
  );
}

export function usePermissions() {
  return useOutletContext<PermissionsContext>();
}
