import React, { ChangeEvent } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';
import {
  Collapse, Table, TableBody, TablePagination,
} from '@mui/material';
import CenteredRowMessage from './shared/CenteredRowMessage';
import Student from '../interfaces/Student';
import FormattedMessageRollover from '../lang/FormattedMessage';
import { useAppContext } from '../context/AppContext';
import SchedulerTableView from './shared/SchedulerTableView';
import { TableRowPagination } from '../styled/styles';
import PaginationSpacer from './shared/PaginationSpacer';
import SearchBar from './shared/SearchBar';
import PaginationLabel from './shared/PaginationLabel';
import LevelSetSchedule from '../interfaces/LevelSetSchedule';
import { parseAssessmentSchedule } from '../util/shared';
import Checkbox from './shared/Checkbox';
import MultiSelectLevelEnum from '../enum/MultiSelectLevel';
import DateRange from '../interfaces/DateRange';
import LocatorTestLevelPopup from './popup/LocatorTestLevelPopup';

interface StudentComponentProps {
  districtId: number,
  schoolId: number,
  hasSummerAccess: boolean,
  classId: number,
  classSchedule: LevelSetSchedule[],
  isRoot?: boolean,
  dateRange: DateRange,
}

const StudentComponent = ({
  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  districtId, schoolId, hasSummerAccess, classId, classSchedule, isRoot, dateRange,
}: StudentComponentProps) => {
  const [page, setPage] = React.useState(0);
  const [perPageRows, setPerPageRows] = React.useState(10);
  const {
    programId, languageId, summer, activeMultiSelectId, schoolYearEnd, httpClient: { getStudentData },
  } = useAppContext();
  const [searchKey, setSearchKey] = React.useState('');
  const { data: students = [], isLoading } = getStudentData({
    districtId, schoolId, classId, programId, languageId, summer, isEnabled: true, schoolYearEnd,
  });

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPerPageRows(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleSearchKeyChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPage(0);
    setSearchKey(event.target.value);
  }

  const getPaginatedRows = (data: Student[]): Student[] => {
    if (data && data.length > perPageRows) {
      return data.slice(page * perPageRows, ((page * perPageRows) + perPageRows))
    }
    return data;
  }

  const getStudentRows = (): Student[] => {
    if (searchKey !== '' && searchKey.length > 0) {
      const res = students?.filter((x) => x.last_name
        .concat('', x.first_name)
        .toLowerCase()
        .replace(/[, ]+/g, '').trim()
        .includes(
          searchKey.toLowerCase().replace(/[, ]+/g, '').trim(),
        ));
      const paginatedRows = getPaginatedRows(res);
      return paginatedRows || [];
    }
    return students;
  }

  const getStudentName = (lastName: string, firstName: string): string => {
    if (lastName && !firstName) {
      return lastName;
    }
    if (!lastName && firstName) {
      return firstName;
    }
    return `${lastName}, ${firstName}`;
  }

  const multiSelectLevelId = `${schoolId}_${MultiSelectLevelEnum.StudentPrefix}_${classId}`;
  return isLoading ? (
    <CenteredRowMessage
      messageId="loading"
      defaultMessage="Loading..."
      componentKey={`studentComponentLoadingMsg_${districtId}_${classId}`}
    />
  ) : (
    <>
      {students.length === 0 ? (
        <CenteredRowMessage
          messageId="noRows"
          defaultMessage="No Rows"
          componentKey={`studentComponentNoRowsMsg_${districtId}_${classId}`}
        />
      ) : (
        <>
          <TableRow>
            <SearchBar
              id="search-student"
              labelId="searchStudent"
              handleSearch={handleSearchKeyChange}
            />
            <Checkbox multiSelectLevelId={multiSelectLevelId} dataTestId="multiSelectTableDataStudent" />
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                border: 'unset', padding: 0,
              }}
              colSpan={7}
            >
              <Collapse in timeout="auto" unmountOnExit>
                <Box sx={{ margin: 0 }}>
                  <Table size="medium" aria-label="table students" data-testid="tableStudents">
                    <TableBody>
                      {students.length > 0 && !isLoading && getPaginatedRows(getStudentRows()).map((student) => {
                        const studentName = getStudentName(student.last_name, student.first_name);
                        return (
                          <TableRow key={`student_${districtId}_${student.user_id}`} data-testid="tableRowStudent">
                            <TableCell style={{
                              paddingBottom: 6, paddingLeft: '2rem', paddingTop: 6, minWidth: 340, width: 340, paddingRight: '0',
                            }}
                            >
                              {activeMultiSelectId === multiSelectLevelId && (
                                <Box style={{ display: 'inline-block', marginLeft: '1.875rem' }}>
                                  <Checkbox
                                    multiSelectLevelId={multiSelectLevelId}
                                    multiSelectChildId={`${MultiSelectLevelEnum.StudentPrefix}${schoolId}_${student.user_id}`}
                                    noTableCell
                                    schoolId={schoolId}
                                    classId={classId}
                                    studentId={student.user_id}
                                    studentName={studentName}
                                    assessments={student.assesments}
                                    dataTestId="checkboxStudent"
                                  />
                                </Box>
                              )}
                              <Box
                                aria-label={studentName}
                                style={{
                                  marginLeft: activeMultiSelectId === multiSelectLevelId ? '1.5rem' : '3rem',
                                  display: 'inline-block',
                                }}
                              >
                                {student.locator_test_enabled ? (
                                  <LocatorTestLevelPopup
                                    studentId={student.user_id ?? 0}
                                    studentName={studentName ?? ''}
                                  />
                                ) : (
                                  <span id="studentInfo" aria-label={studentName}>
                                    {studentName}
                                  </span>
                                )}
                              </Box>
                            </TableCell>
                            <SchedulerTableView
                              scheduler={parseAssessmentSchedule(student.assesments, classSchedule)}
                              hasSummerAccess={hasSummerAccess}
                              keyPrefix={`student_${student.user_id}`}
                              multiSelectLevel={multiSelectLevelId}
                              schoolId={schoolId}
                              classId={classId}
                              studentId={student.user_id}
                              studentName={getStudentName(student.last_name, student.first_name)}
                              dateRange={dateRange}
                            />
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
          <TableRowPagination justifyContent="flex-start">
            <td>
              <TablePagination
                id="pagination-students"
                rowsPerPageOptions={[5, 10, 20, 40]}
                component="div"
                count={getStudentRows().length}
                rowsPerPage={perPageRows}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                labelRowsPerPage={<FormattedMessageRollover id="rowsPerPage" />}
                labelDisplayedRows={({ from, to, count }) => (
                  <PaginationLabel from={from} to={to} count={count} />
                )}
              />
            </td>
          </TableRowPagination>
          <PaginationSpacer />
        </>
      )}
    </>
  )
}

export default StudentComponent;
