import React from 'react';
import FormControl from '@mui/material/FormControl/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { MenuItem, Select } from '@mui/material';
import styled from '@emotion/styled';
import { useAppContext } from '../context/AppContext';
import FormattedMessageRollover from '../lang/FormattedMessage';
import ProgramEnum from '../enum/Program';

const YearSelector = () => {
  const {
    schoolYearEnd, setSchoolYearEnd, isLocal, programId,
  } = useAppContext();
  const buildMenuItems = (): number[] => {
    const currentYearEnd = new Date().getFullYear();
    if (new Date().getMonth() >= 7) {
      // if >= July, do not display the previous school year
      return [currentYearEnd + 1];
    }
    return [currentYearEnd + 1, currentYearEnd];
  }

  const getTop = () => {
    if (isLocal) {
      return '5rem'
    }
    return programId === (ProgramEnum.LanguageArt) ? '8.5rem' : '12.5rem'
  }
  const YearSelectorDiv = styled.div`
    position: absolute;
    top: ${getTop()};
    right:0.3rem;
    margin-right: 0;
    margin-left: 0;
    padding: 0;
    z-index: 100;
    box-shadow: rgb(0 0 0 / 50%) 0px 2px 10px;
  `
  return (
    <YearSelectorDiv>
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="school-year-select">
          <FormattedMessageRollover id="schoolYear" defaultMessage="School Year" />
        </InputLabel>
        <Select
          labelId="school-year-select"
          value={schoolYearEnd}
          label="schoolYear"
          onChange={(event) => {
            setSchoolYearEnd(event.target.value as number)
          }}
        >
          {buildMenuItems().map((yearEnd) => (
            <MenuItem value={yearEnd} key={`schoolYear_${yearEnd}`}>
              {yearEnd - 1}
              {' '}
              -
              {' '}
              {yearEnd}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </YearSelectorDiv>
  );
}

export default YearSelector;
