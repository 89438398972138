import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { UseQueryResult } from 'react-query';
import District from '../../interfaces/District';
import { ClassAPIData, LevelsetAPIBase, StudentAPIData } from '../../interfaces/api';
import School from '../../interfaces/School';
import Class from '../../interfaces/Class';
import Student from '../../interfaces/Student';
import LevelSetSchedule from '../../interfaces/LevelSetSchedule';
import PostScheduleObject from '../../interfaces/PostScheduleObject';
import DateRangeResponse from '../../interfaces/api/DateRangeResponse';
import ErrorDetail from '../../util/ErrorDetail';
import PermissionsResponse from '../../interfaces/api/Permissions/PermissionsResponse';
import PermissionsRequest from '../../interfaces/api/Permissions/PermissionsRequest';
import SavePermissionsRequest from '../../interfaces/api/Permissions/SavePermissionsRequest';
import GetResetEligibilityRequest from '../../interfaces/api/reset/GetResetEligibilityRequest';
import GetResetEligibilityResponse from '../../interfaces/api/reset/GetResetEligibilityResponse';
import ResetLevelSetTestRequest from '../../interfaces/api/reset/ResetLevelSetTestRequest';
import LocatorTestLevelsRequest from '../../interfaces/api/LocatorTestLevelsRequest';
import LocatorTestLevelsResponse from '../../interfaces/api/LocatorTestLevelsResponse';
import UserLocatorTestLevelRequest from '../../interfaces/api/UserLocatorTestLevelRequest';
import UserLocatorTestLevelResponse from '../../interfaces/api/UserLocatorTestLevelResponse';

export interface HttpClientConfig {
  baseURL: string,
  headers: {}
}

export default abstract class HttpClient {
  private readonly instance: AxiosInstance

  protected constructor(config: HttpClientConfig) {
    this.instance = axios.create(config)
    this.initializeResponseInterceptor();
  }

  private initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      ({ data }: AxiosResponse) => data,
      (error: AxiosError) => Promise.reject(error),
    );
  };

  protected getInstance(): AxiosInstance {
    return this.instance;
  }

  abstract getDistrictData(isEnabled: boolean, programId: string): UseQueryResult<District[]>

  abstract getSchoolData(
    {
      districtId, programId, languageId, isEnabled, summer, schoolYearEnd,
    }: LevelsetAPIBase
  ): UseQueryResult<School[], unknown>

  abstract getClassData(
    {
      districtId, schoolId, programId, languageId, summer, isEnabled, schoolYearEnd,
    }: ClassAPIData
  ): UseQueryResult<Class[], unknown>

  abstract getStudentData(
    {
      districtId, schoolId, programId, languageId, classId, summer, isEnabled, schoolYearEnd,
    }: StudentAPIData
  ): UseQueryResult<Student[], unknown>

  abstract getLevelsetSchedule(
    {
      districtId, programId, languageId, isEnabled, summer, schoolYearEnd,
    }: LevelsetAPIBase, schoolId?: number, classId?: number, userId?: number
  ): UseQueryResult<LevelSetSchedule[], unknown>

  abstract getSchoolAdminData(
    {
      districtId, programId, languageId, isEnabled, summer, schoolYearEnd,
    }: LevelsetAPIBase, userId: number,
  ): UseQueryResult<School[], unknown>

  abstract getTeacherAdminData(
    {
      districtId, programId, languageId, isEnabled, summer, schoolYearEnd,
    }: LevelsetAPIBase, userId: number, schoolId: number
  ): UseQueryResult<Class[], unknown>

  abstract getDateRange(districtId:number, schoolId:number, isEnabled:boolean, schoolYearEnd: number): UseQueryResult<DateRangeResponse, unknown>

  abstract postLevelSetSchedule(data: PostScheduleObject): Promise<string[]| ErrorDetail>

  abstract getPermissions(data: PermissionsRequest): UseQueryResult<PermissionsResponse, unknown>

  abstract savePermissions(data: SavePermissionsRequest): Promise<[]| ErrorDetail>

  abstract getResetEligibility(data: GetResetEligibilityRequest): UseQueryResult<GetResetEligibilityResponse, unknown>

  abstract resetLevelSetTest(data: ResetLevelSetTestRequest): Promise<string[]| ErrorDetail>

  abstract getLocatorTestLevels(data: LocatorTestLevelsRequest):UseQueryResult<LocatorTestLevelsResponse[], unknown>

  abstract getUserLocatorTestLevel(data: UserLocatorTestLevelRequest): UseQueryResult<UserLocatorTestLevelResponse, unknown>

  abstract saveUserLocatorTestLevel(data: UserLocatorTestLevelRequest): Promise<UserLocatorTestLevelResponse | ErrorDetail>
}
