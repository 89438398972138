import React, { useState } from 'react';
import './MuiClassNameSetup';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import HomeComponent from './components/Home';
import User from './interfaces/User';
import AppContext, { AppContextInterface } from './context/AppContext';
import LanguageEnum from './enum/Language';
import SummerEnum from './enum/Summer';
import Roles from './enum/Roles';
import HttpClient from './service/apiHost/HttpClient';
import Client from './enum/Client';
import HttpClientCreator from './service/creatorFactory/HttpClientCreator';
import ErrorBoundary from './util/ErrorBoundary';
import LanguageProvider from './Provider/LanguageProvider';
import MultiSelectLevelEnum from './enum/MultiSelectLevel';
import ThemeProvider from './Provider/ThemeProvider';
import { getCurrentSchoolYear } from './util/shared';
import Permissions from './components/Permissions';
import NavBar from './components/shared/NavBar';
import Error404Element from './components/404';

interface AppProps {
  user: User;
  clientType: Client;
}

const cache = new QueryCache();
const queryClient = new QueryClient({ queryCache: cache })
const App = ({ user, clientType } : AppProps) => {
  const instance: HttpClient|null = HttpClientCreator.createObject(clientType, user.id);
  if (instance === null) {
    // eslint-disable-next-line no-console
    console.error('Http Client type should have a valid value');
    return <h1>Error! Please try again later</h1>;
  }
  const [districtId, setDistrictId] = useState<number>(
    user.role === Roles.DISTRICT_ADMIN || user.role === Roles.SCHOOL_ADMIN || user.role === Roles.TEACHER_ADMIN
      ? user.districtId
      : 0,
  );
  const [languageId, setLanguageId] = useState<LanguageEnum>(LanguageEnum.English);
  const [summer, setSummer] = useState<SummerEnum>(SummerEnum.No);
  const [disableDatePicker, setDisableDatePicker] = useState<boolean>(!user.isEditable);
  const [allowReset, setAllowReset] = useState<boolean>(false);
  const [isInterimIITestDisabled, setInterimIITestDisabled] = useState<boolean>(true);
  const [activeMultiSelectId, setActiveMultiSelectId] = useState<MultiSelectLevelEnum|string>(MultiSelectLevelEnum.Null);
  const [schoolYearEnd, setSchoolYearEnd] = useState<number>(getCurrentSchoolYear());

  const setIsSummer = (isSummer: SummerEnum) => {
    setSummer(isSummer);
  }

  const setSelectedLanguageId = (newLang: LanguageEnum) => {
    setLanguageId(newLang);
    setIsSummer(SummerEnum.No);
  }

  const setSelectedDistrictId = (newDistrictId: number) => {
    setDistrictId(newDistrictId);
    setIsSummer(SummerEnum.No);
  }

  const setSelectedSchoolYearEnd = (newValue: number) => {
    setSchoolYearEnd(newValue);
    setIsSummer(SummerEnum.No);
  }
  const map = new Map();

  const appContextDefault: AppContextInterface = {
    user,
    programId: user.programId,
    languageId,
    districtId,
    summer,
    setSelectedDistrictId,
    setSelectedLanguageId,
    setIsSummer,
    httpClient: instance,
    activeMultiSelectId,
    setActiveMultiSelectId: ((newMultiSelectId) => { setActiveMultiSelectId(newMultiSelectId); map.clear(); }),
    multiSelectedRecordsMap: map,
    disableDatePicker,
    setDisableDatePicker: ((newValue) => setDisableDatePicker(newValue)),
    allowReset,
    setAllowReset: ((newValue) => setAllowReset(newValue)),
    schoolYearEnd,
    setSchoolYearEnd: ((newValue) => setSelectedSchoolYearEnd(newValue)),
    isInterimIITestDisabled,
    setInterimIITestDisabled: ((newValue) => setInterimIITestDisabled(newValue)),
    isLocal: window.location.host.includes('localhost'),
  }

  const router = createBrowserRouter([
    {
      path: 'levelset/scheduler',
      element: <NavBar />,
      children: [{
        index: true, element: <HomeComponent userData={user} />,
      },
      {
        path: 'permissions',
        element: <Permissions />,
      },
      ],
    },
    {
      path: '/levelset/scheduler/*',
      element: <Error404Element />,
    },
  ]);

  return (
    <QueryClientProvider client={queryClient}>
      <LanguageProvider languageId={user.languageId}>
        <ErrorBoundary>
          <AppContext.Provider value={appContextDefault}>
            <ThemeProvider>
              <RouterProvider router={router} />
            </ThemeProvider>
          </AppContext.Provider>
        </ErrorBoundary>
        <ReactQueryDevtools />
      </LanguageProvider>
    </QueryClientProvider>
  );
}

export default App;
